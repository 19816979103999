export function getGeneralConditionsByType(facType: string): string[] {
    const docMappings = new Map<string, Set<string>>()

    docMappings.set('property', new Set<string>([
        'AIG U.S. Property Certificate (04/16)',
        'Chubb Property Reinsurance General Conditions - FAC-39670a (08/13)',
        'Lockton Re LLC Certificate Form LP1002 – September 2019',
        'Palms Certificate eff. 7/1/2023',
        'Palms Certificate eff. 7/1/2023 – NI edits 4/1/24'
    ]))

    docMappings.set('casualty', new Set<string>([
        'AIG U.S. Casualty Certificate (04/16)',
        'Chubb Casualty Reinsurance General Conditions FAC39671a 0813',
        'Lockton Re LLC Certificate Form LP1002 - November 2022',
        'Lockton Re LLC Certificate Form LP1002 - November 2022 (with Wisconsin Governing Law Addendum)'
    ]))
    docMappings.set('both', new Set<string>([
        'CNA General Conditions 092914',
        'Everest Insurance v.3.19.2020',
        'Everest Insurance v.4.2.26.2021',
        'Everest Munich Re Fac Re Cert v.11.25.2019',
        'Everest Swiss Re Fac Cert',
        'FM',
        'FM Global Fac Certificate Form CP2007 – 09-01-10',
        'Liberty Mutual Insurance Company Form No. LM-1000-FC(Edition 12/31/2010)',
        'Allied World Assurance Company General Terms & Conditions Ref: NACF00002 100 (10/17) (Edition October 2017)',
        'Starr Facultative General Conditions US 01012023',
        'The Hartford Reinsurance Conditions',
        'Travelers– includes policy conditions, CP-6067',
    ]))

    const results: string[] = []

    if (docMappings.has(facType)){
        docMappings.get(facType)?.forEach(docName => results.push(docName))
    }

    // return all docs that map to 'both' prop and casualty
    if (docMappings.has('both')){
        docMappings.get('both')?.forEach(docName => results.push(docName))
    }

    return results
  }
