import { createAction, props } from '@ngrx/store'
import { ApplicationError } from '../../error'
import {
  CreateFacDataReturn,
  FacBasisOfAcceptance,
  FacCurrency,
  FacReinsuranceConditions,
  FacReinsurer,
  FacTermsAndConditions,
  PostEmailElement,
  PostFacDataElement,
} from '@api/model'
import {
  DealSummariesResult,
  FacData,
  FacDataVersion,
  Facultative,
  FacUser,
  DealSearchCriteria,
  ISort, NewFormFields, ReinsuredLayer,
  SalesforceAccount, SalesforceContact,
} from '../models'

const featureAreaName = 'Deals'

export const setSidebarCategories = createAction(
  `[${featureAreaName}] Set Sidebar Categories`,
  props<{ facDataVersions: FacDataVersion[] }>()
)

export const dealSummariesLoad = createAction(
  `[${featureAreaName}] Load Deal Summaries`
)
export const dealSummariesLoadSuccess = createAction(
  `[${featureAreaName}] Load Deal Summaries Success`,
  props<{ data: DealSummariesResult }>()
)
export const dealSummariesLoadFail = createAction(
  `[${featureAreaName}] Load Deal Summaries Fail`,
  props<{ errorMessage: string }>()
)

export const loadBrokerFilterPicklist = createAction(
  `[${featureAreaName}] Load Broker Filter Picklist`
)
export const loadBrokerFilterPicklistSuccess = createAction(
  `[${featureAreaName}] Load Broker Filter Picklist Success`,
  props<{ brokerPicklistItems: readonly string[] }>()
)
export const loadBrokerFilterPicklistFailure = createAction(
  `[${featureAreaName}] Load Broker Filter Picklist Fail`,
  props<{ errorMessage: string }>()
)

export const loadAllFacUsers = createAction(
  `[${featureAreaName}] Load All Facultative Users`
)
export const loadAllFacUsersSuccess = createAction(
  `[${featureAreaName}] Load All Facultative Users Success`,
  props<{ users: readonly FacUser[] }>()
)
export const loadAllFacUsersFailure = createAction(
  `[${featureAreaName}] Load All Facultative Users Fail`,
  props<{ errorMessage: string }>()
)

export const setNewFormFields = createAction(
  `[${featureAreaName}] Set New Form Fields`,
  props<{ fields: NewFormFields }>()
)

export const getFacultativeDeal = createAction(
  `[${featureAreaName}] Load Deal`,
  props<{ dealId: string }>()
)
export const getFacultativeDealSuccess = createAction(
  `[${featureAreaName}] Load Deal Success`,
  props<{ data: Facultative }>()
)
export const getFacultativeDealFail = createAction(
  `[${featureAreaName}] Load Deal Fail`,
  props<{ errorMessage: string }>()
)

export const deleteFacultativeData = createAction(
  `[${featureAreaName}] Delete Facultative Data`,
  props<{ id: number }>()
)

export const deleteFacultativeDataSuccess = createAction(
  `[${featureAreaName}] Delete Facultative Data Success`,
  props<{ data: { id: string } }>()
)

export const deleteFacultativeDataFailure = createAction(
  `[${featureAreaName}] Delete Facultative Data Failure`,
  props<{ error: ApplicationError }>()
)

export const replicateFacultativeData = createAction(
  `[${featureAreaName}] Replicate Facultative Data`,
  props<{
    id: string
    name: string
    ntu?: string
    nameOfInsured: string
    reinsured: string
    reinsuredUW: string
    reinsuredName: string
    reinsuredId: string
    renewal?: boolean
  }>()
)

export const replicateFacultativeDataSuccess = createAction(
  `[${featureAreaName}] Replicate Facultative Data Success`,
  props<{ data: Facultative | undefined }>()
)

export const replicateFacultativeDataFailure = createAction(
  `[${featureAreaName}] Replicate Facultative Data Failure`,
  props<{ error: ApplicationError }>()
)

export const updateDealBroker = createAction(
  `[${featureAreaName}] Update Deal Broker`,
  props<{ dealId: number; brokerName: string; }>()
)

export const updateDealBrokerSuccess = createAction(
  `[${featureAreaName}] Update Deal Broker Success`,
  props<{ dealId: number; brokerName: string; }>()
)

export const updateDealBrokerFailure = createAction(
  `[${featureAreaName}] Update Deal Broker Failure`,
  props<{ error: ApplicationError }>()
)

export const markDealAsNTU = createAction(
  `[${featureAreaName}] Mark Deal As NTU`,
  props<{ dealId: number; ntuComment: string }>()
)

export const markDealAsNTUSuccess = createAction(
  `[${featureAreaName}] Mark Deal As NTU Success`,
  props<{ dealId: number; ntuComment: string }>()
)

export const markDealAsNTUFailure = createAction(
  `[${featureAreaName}] Mark Deal As NTU Failure`,
  props<{ error: ApplicationError }>()
)

export const setCurrentDealName = createAction(
  `[${featureAreaName}] Set Current Deal Name`,
  props<{ currentDealName: string }>()
)

export const updateCurrentSettings = createAction(
  `[${featureAreaName}] Update Current Saved Settings`,
  props<{
    setting: { key: string; value: string | number | boolean | ISort }
  }>()
)

export const getFacUsersConcurrent = createAction(
  `[${featureAreaName}] Get User Data Concurrently`
)

export const createFacultativeData = createAction(
  `[${featureAreaName}] Create Facultative Data`,
  props<{ dataEntry: PostFacDataElement }>()
)

export const createFacultativeDataSuccess = createAction(
  `[${featureAreaName}] Create Facultative Data Success`,
  props<{ data: CreateFacDataReturn }>()
)

export const createFacultativeDataFailure = createAction(
  `[${featureAreaName}] Create Facultative Data Failure`,
  props<{ error: ApplicationError }>()
)

// Fac Data Form Version
export const createFacultativeFormVersion = createAction(
  `[${featureAreaName}] Create Facultative Form Version`,
  props<{ facDataVersion: FacDataVersion; skipRedirect?: boolean }>()
)

export const updateReinsuredLayers = createAction(
  `[${featureAreaName}] Update Reinsured Layers`,
  props<{ reinsuredLayers: ReinsuredLayer[] }>()
)

export const updateReinsuredNameAndAddress = createAction(
  `[${featureAreaName}] Update Reinsured Name and Address`,
  props<{ nameAndAddress: string | null }>()
)

export const updateReinsuranceCertNo = createAction(
  `[${featureAreaName}] Update Reinsurance Certificate Number`,
  props<{ reinsuranceCertificateNo: string | null }>()
)

export const updatePolicyNo = createAction(
  `[${featureAreaName}] Update Policy Number`,
  props<{ policyNumber: string | null }>()
)

export const createFacultativeFormVersionSuccess = createAction(
  `[${featureAreaName}] Create Facultative Form Version Success`,
  props<{ data: FacDataVersion; skipRedirect?: boolean }>()
)

export const createFacultativeFormVersionFailure = createAction(
  `[${featureAreaName}] Create Facultative Form Version Failure`,
  props<{ error: ApplicationError }>()
)

export const saveFacultativeFormVersion = createAction(
  `[${featureAreaName}] Save Facultative Form Version`,
  props<{ facDataVersion: FacDataVersion; skipRedirect?: boolean; skipFacData?: boolean }>()
)

export const saveFacultativeFormVersionSuccess = createAction(
  `[${featureAreaName}] Save Facultative Form Version Success`,
  props<{ facData: FacData, facDataVersion: FacDataVersion; skipRedirect?: boolean }>()
)

export const saveFacultativeFormVersionFailure = createAction(
  `[${featureAreaName}] Save Facultative Form Version Failure`,
  props<{ error: ApplicationError }>()
)

export const deleteFacultativeFormVersion = createAction(
  `[${featureAreaName}] Delete Facultative Form Version`,
  props<{ versionId: number }>()
)

export const deleteFacultativeFormVersionSuccess = createAction(
  `[${featureAreaName}] Delete Facultative Form Version Success`,
  props<{ data: { id: string } }>()
)

export const deleteFacultativeFormVersionFailure = createAction(
  `[${featureAreaName}] Delete Facultative Form Version Failure`,
  props<{ error: ApplicationError }>()
)

export const setCurrentDealType = createAction(
  `[${featureAreaName}] Set Current Deal Type`,
  props<{ currentDealType: string | null }>()
)

export const setCurrentDeal = createAction(
  `[${featureAreaName}] Set Current Deal`,
  props<{ currentDeal: number; currentDealType: string | null }>()
)

export const setCurrentFormCategory = createAction(
  `[${featureAreaName}] Set Current Form Category`,
  props<{ currentType: string | null }>()
)

export const setCurrentViewPreference = createAction(
  `[${featureAreaName}] Set Current View Preferences`,
  props<{ currentViewPreference: string }>()
)

export const setCurrentVersion = createAction(
  `[${featureAreaName}] Set Current Version`,
  props<{ currentVersion: FacDataVersion | null }>()
)

export const setExportingPdf = createAction(
  `[${featureAreaName}] Exporting PDF`,
  props<{ exporting: boolean }>()
)

export const setDirty = createAction(
  `[${featureAreaName}] Set Dirty`,
  props<{ dirty: boolean }>()
)

export const setSearchCriteria = createAction(
  `[${featureAreaName}] Set Current Search Criteria`,
  props<{ searchCriteria: DealSearchCriteria }>()
)

export const resetSearchCriteria = createAction(`[${featureAreaName}] Reset Search Criteria`)

export const sendEmail = createAction(
  `[${featureAreaName}] Send Email`,
  props<{ dataEntry: PostEmailElement }>()
)

export const updateFiles = createAction(
  `[${featureAreaName}] Update Files`,
  props<{ storedFiles: string }>()
)

export const sendEmailSuccess = createAction(
  `[${featureAreaName}] Send Email Success`,
  props<{ data: PostEmailElement }>()
)

export const backendRequestFailure = createAction(
  `[${featureAreaName}] Fac Backend Request Failure`,
  props<{ errorMessage: string }>()
)

export const sendEmailFailure = createAction(
  `[${featureAreaName}] Send Email Failure`,
  props<{ error: ApplicationError }>()
)

export const setCoverageType = createAction(
  `[${featureAreaName}] Set Coverage Reinsured Type`,
  props<{ coverageReinsured: string }>()
)

export const setSharedFieldValues = createAction(
  `[${featureAreaName}] Set Shared Field Values`,
  props<{ field: string; value: string }>()
)

export const loadBasisOfAcceptance = createAction(
  `[${featureAreaName}] Load Basis of Acceptance`
)

export const loadBasisOfAcceptanceSuccess = createAction(
  `[${featureAreaName}] Load Basis of Acceptance Success`,
  props<{ basisOfAcceptance: FacBasisOfAcceptance[] }>()
)

export const loadSalesforceAccounts = createAction(
  `[${featureAreaName}] Load Salesforce Accounts`
)

export const loadSalesforceAccountsSuccess = createAction(
  `[${featureAreaName}] Load Salesforce Accounts Success`,
  props<{ salesforceAccounts: SalesforceAccount[] }>()
)

export const loadSalesforceAccountsFailure = createAction(
  `[${featureAreaName}] Load Salesforce Accounts Failure`,
  props<{ errorMessage: string }>()
)

export const loadTermsAndConditions = createAction(
  `[${featureAreaName}] Load Terms and Conditions`
)

export const loadTermsAndConditionsSuccess = createAction(
  `[${featureAreaName}] Load Terms and Conditions Success`,
  props<{ termsAndConditions: FacTermsAndConditions[] }>()
)

export const loadReinsurers = createAction(
  `[${featureAreaName}] Load Reinsurer List`
)

export const loadReinsurersSuccess = createAction(
  `[${featureAreaName}] Load Reinsurer List Success`,
  props<{ reinsurersList: FacReinsurer[] }>()
)

export const loadReinsuranceConditions = createAction(
  `[${featureAreaName}] Load Reinsurance Conditions`
)

export const loadReinsuranceConditionsSuccess = createAction(
  `[${featureAreaName}] Load Reinsurance Conditions Success`,
  props<{ reinsuranceConditions: FacReinsuranceConditions[] }>()
)

export const loadCurrencies = createAction(
  `[${featureAreaName}] Load Currencies`
)

export const loadCurrenciesSuccess = createAction(
  `[${featureAreaName}] Load Currencies Success`,
  props<{ currency: FacCurrency[] }>()
)

export const formSaveStarted = createAction(
  `[${featureAreaName}] Form Save Started`
)

export const getSalesforceContacts = createAction(
  `[${featureAreaName}] Get Salesforce Contacts`
)

export const getSalesforceContactsSuccess = createAction(
  `[${featureAreaName}] Get Salesforce Contacts Success`,
  props<{ salesforceContacts: SalesforceContact[] }>()
)

export const toggleDealSidebar = createAction(
  `[${featureAreaName}] Toggle Deal Sidebar`,
)

export const setHeaderTitle = createAction(
  `[${featureAreaName}] Set Header Title`,
  props<{ headerTitle: string }>()
)
