import { curry } from 'ramda'
import { formatThousandsCommas, stripNonNumeric, } from '../../formatters'
import { FacExportData, FacFormID, FacUnderwritingVisibility } from '../../models'
import {
  DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
  FacExportField
} from './models'

type FacCoverageType =
  | 'General Liability'
  | 'Auto Liability'
  | 'Umbrella / Excess Liability'
  | 'Umbrella / Excess Liability - General Liability Carve Out'
  | 'Umbrella / Excess Liability - Auto Liability Carve Out'
  | "Excess Auto Liability"
  | "Workers' Compensation"
  | "Cyber Liability"
  | "Directors & Officers Liability"
  | "Errors & Omissions Liability"
  | "Miscellaneous Liability"
  | 'cert'

export interface UnderwritingDefinition {
  id?: string
  name: string
  columns?: string[]
  columnWidths?: number[]
  types?: string[]
  ordinal?: boolean
}

type UnderwritingValue = string | Record<string, string>[]
type UnderwritingDict = Record<string, UnderwritingValue>

const CUSTOM_ID_TOTAL_PAYROLL = '__total_payroll'

const AutoLiabiltyTables: UnderwritingDefinition[] = [
  {
    id: 'excessLiabilityScheduleOfUnderlyingLimits',
    name: 'Schedule of Underlying Limits',
    columns: [
      'LOB',
      'Limits',
      'Premium',
      'Carrier',
      'See Attached U/L Schedule',
    ],
    types: ['string', 'string', 'currency', 'string', 'select'],
  },
  { name: 'Underwriting Information' },
  {
    id: 'excessLiabilityExposures',
    name: 'Exposures',
    columns: ['Category', 'Exposure'],
    types: ['string', 'currency'],
  },
  {
    id: 'excessAutoLiabilityFleetBreakout',
    name: 'Auto Fleet Breakout',
    columns: [
      'Vehicle Type',
      'Local',
      'Intermediate',
      'Long Distance',
      'Total',
    ],
    types: ['string', 'number', 'number', 'number', 'total'],
  },
  {
    id: 'autoLiabilityRadius',
    name: 'Radius',
  },
  {
    id: 'autoLiabilityMileage',
    name: 'Mileage',
  },
  {
    id: 'excessLiabilityHiredNonOwnedExposures',
    name: 'Hired Non Owned Exposures',
  },
  {
    id: 'excessLiabilityGarageLocations',
    name: 'Garage Locations',
    columns: ['State', 'Vehicles'],
    types: ['string', 'number'],
  },
  {
    id: 'excessLiabilityHistoricalInformation',
    name: 'Auto Historical Exposures',
    columns: ['Year', 'Units', 'Mileage', 'Other'],
    types: ['string', 'string', 'number', 'string'],
  },
  {
    id: 'excessAutoLiabilityLossHistory',
    name: 'Auto Liability Aggregate Loss History',
    columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
    types: ['string', 'string', 'currency', 'currency', 'date'],
  },
  {
    id: 'excessLiabilityLossHistory',
    name: 'Umbrella / Excess Liability Loss History',
    columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
    types: ['string', 'string', 'currency', 'currency', 'date'],
  },
  {
    id: 'excessLiabilityExcessOf',
    name: 'Loss History Excess of ',
  },
  {
    id: 'excessLiabilityLargeLossHistoryExcessOf',
    name: '',
    columns: ['LOB', 'DOL', 'Total Incurred', 'Status', 'Description'],
    columnWidths: [0.12, 0.11, 0.15, 0.07, 0.55],
    types: ['string', 'date', 'currency', 'string', 'string'],
  },
]

const ExcessLiabilitySUL: UnderwritingDefinition = {
  id: 'excessLiabilityScheduleOfUnderlyingLimits',
  name: 'Schedule of Underlying Limits',
  columns: [
    'LOB',
    'Limits',
    'Premium',
    'Carrier',
    'See Attached U/L Schedule',
  ],
  types: ['string', 'string', 'currency', 'string', 'select'],
}

const ExcessGeneralLiabilityLossHistory: UnderwritingDefinition = {
  id: 'excessGeneralLiabilityLossHistory',
  name: 'Loss History',
  columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
  types: ['string', 'string', 'currency', 'currency', 'date'],
}

const ExcessLiabilityLargeLossHistoryExcessOf: UnderwritingDefinition = {
  id: 'excessLiabilityLargeLossHistoryExcessOf',
  name: '',
  columns: ['LOB', 'DOL', 'Total Incurred', 'Status', 'Description'],
  columnWidths: [0.12, 0.11, 0.15, 0.07, 0.55],
  types: ['string', 'date', 'currency', 'string', 'string'],
}

const DEFS: Record<FacCoverageType, UnderwritingDefinition[]> = {
  'General Liability': [
    { name: 'Underwriting Information' },
    {
      id: 'generalLiabilitySalesBreakout',
      name: 'Exposures',
      columns: ['Category', 'Exposure'],
      types: ['string', 'number'],
    },
    {
      id: 'generalLiabilityHistoricalExposure',
      name: 'Historical Exposures',
      columns: ['Year', 'Gross Receipts', 'Payroll'],
      types: ['string', 'currency', 'currency'],
    },
    {
      id: 'generalLiabilityLossHistory',
      name: 'General Liability Loss History',
      columns: ['Years', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'generalLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    {
      id: 'noLargeLosses',
      name: 'noLargeLosses',
    },
    {
      id: 'generalLiabilityLossHistoryExcessOf',
      name: '',
      columns: ['DOL', 'Total Incurred', 'Status', 'Description'],
      types: ['date', 'currency', 'string', 'string'],
    },
  ],
  'Auto Liability': [
    { name: 'Underwriting Information' },
    {
      id: 'autoLiabilityFleetBreakout',
      name: 'Fleet Breakout',
      columns: [
        'Vehicle Type',
        'Local',
        'Intermediate',
        'Long Distance',
        'Total',
      ],
      types: ['string', 'number', 'number', 'number', 'total'],
    },
    {
      id: 'autoLiabilityRadius',
      name: 'Radius',
    },
    {
      id: 'autoLiabilityMileage',
      name: 'Mileage',
    },
    {
      id: 'autoLiabilityHiredNonOwnedExposures',
      name: 'Hired Non Owned Exposures',
    },
    {
      id: 'autoLiabilityGarageLocations',
      name: 'Garage Locations',
      columns: ['State', 'Vehicles', 'Total'],
      types: ['string', 'number', 'total'],
    },
    {
      id: 'autoLiabilityHistoricalExposure',
      name: 'Auto Historical Exposures',
      columns: ['Year', 'Units', 'Mileage', 'Other'],
      types: ['string', 'string', 'number', 'string'],
    },
    {
      id: 'autoLiabilityLossHistory',
      name: 'Auto Liability Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'autoLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    {
      id: 'autoLiabilityLossHistoryExcessOf',
      name: '',
      columns: ['DOL', 'Total Incurred', 'Status', 'Description'],
      types: ['date', 'currency', 'string', 'string'],
    },
  ],
  'Umbrella / Excess Liability': [
    {
      id: 'excessLiabilityScheduleOfUnderlyingLimits',
      name: 'Schedule of Underlying Limits',
      columns: [
        'LOB',
        'Limits',
        'Premium',
        'Carrier',
        'See Attached U/L Schedule',
      ],
      types: ['string', 'string', 'currency', 'string', 'select'],
    },
    { name: 'Underwriting Information' },
    {
      id: 'excessLiabilityExposures',
      name: 'Exposures',
      columns: ['Category', 'Exposure'],
      types: ['string', 'currency'],
    },
    {
      id: 'excessLiabilityYearEstimates',
      name: 'General Liability Historical Exposures',
      columns: ['Year', 'Gross Receipts', 'Payroll', 'Other'],
      types: ['string', 'currency', 'currency', 'string'],
    },
    {
      id: 'excessAutoLiabilityFleetBreakout',
      name: 'Auto Fleet Breakout',
      columns: [
        'Vehicle Type',
        'Local',
        'Intermediate',
        'Long Distance',
        'Total',
      ],
      types: ['string', 'number', 'number', 'number', 'total'],
    },
    {
      id: 'autoLiabilityRadius',
      name: 'Radius',
    },
    {
      id: 'autoLiabilityMileage',
      name: 'Mileage',
    },
    {
      id: 'excessLiabilityHiredNonOwnedExposures',
      name: 'Hired Non Owned Exposures',
    },
    {
      id: 'excessLiabilityGarageLocations',
      name: 'Garage Locations',
      columns: ['State', 'Vehicles'],
      types: ['string', 'number'],
    },
    {
      id: 'excessLiabilityHistoricalInformation',
      name: 'Auto Historical Exposures',
      columns: ['Year', 'Units', 'Mileage', 'Other'],
      types: ['string', 'string', 'number', 'string'],
    },
    {
      id: 'excessAutoLiabilityLossHistory',
      name: 'Auto Liability Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'excessGeneralLiabilityLossHistory',
      name: 'General Liability Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'excessLiabilityLossHistory',
      name: 'Umbrella / Excess Liability Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    {
      id: 'excessLiabilityLargeLossHistoryExcessOf',
      name: '',
      columns: ['LOB', 'DOL', 'Total Incurred', 'Status', 'Description'],
      columnWidths: [0.12, 0.11, 0.15, 0.07, 0.55],
      types: ['string', 'date', 'currency', 'string', 'string'],
    },
  ],
  'Umbrella / Excess Liability - General Liability Carve Out': [
    {
      id: 'excessLiabilityScheduleOfUnderlyingLimits',
      name: 'Schedule of Underlying Limits',
      columns: [
        'LOB',
        'Limits',
        'Premium',
        'Carrier',
        'See Attached U/L Schedule',
      ],
      types: ['string', 'string', 'currency', 'string', 'select'],
    },
    { name: 'Underwriting Information' },
    {
      id: 'excessLiabilityExposures',
      name: 'Exposures',
      columns: ['Category', 'Exposure'],
      types: ['string', 'currency'],
    },
    {
      id: 'excessLiabilityYearEstimates',
      name: 'General Liability Historical Exposures',
      columns: ['Year', 'Gross Receipts', 'Payroll', 'Other'],
      types: ['string', 'currency', 'currency', 'string'],
    },
    {
      id: 'excessGeneralLiabilityLossHistory',
      name: 'General Liability Aggregate Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'excessLiabilityLossHistory',
      name: 'Umbrella / Excess Liability Loss History',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    {
      id: 'excessLiabilityLargeLossHistoryExcessOf',
      name: '',
      columns: ['LOB', 'DOL', 'Total Incurred', 'Status', 'Description'],
      columnWidths: [0.12, 0.11, 0.15, 0.07, 0.55],
      types: ['string', 'date', 'currency', 'string', 'string'],
    },
  ],
  'Umbrella / Excess Liability - Auto Liability Carve Out': AutoLiabiltyTables,
  'Excess Auto Liability': AutoLiabiltyTables.filter(table => table.id !== 'excessLiabilityLossHistory'),
  "Workers' Compensation": [
    { name: 'Underwriting Information' },
    { id: 'workersCompPayrollADC', name: 'Payroll ADC' },
    { id: 'workersCompPayrollRDC', name: 'Payroll RDC' },
    { id: CUSTOM_ID_TOTAL_PAYROLL, name: 'Total Payroll' },
    { id: 'workersCompUnmodifiedPremium', name: 'Unmodified Premium' },
    {
      id: 'workersCompHistoricalExposure',
      name: 'Historical Exposure',
      columns: ['Year', 'Payroll ADC', 'Payroll RDC'],
      types: ['string', 'currency', 'currency'],
    },
    {
      id: 'workersCompLossHistoryADC',
      name: 'Loss History - ADC',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'number', 'number', 'date'],
    },
    {
      id: 'workersCompLossHistoryRDC',
      name: 'Loss History - RDC',
      columns: ['Year', 'Claims', 'Total Incurred', 'Total Paid', 'Val Date'],
      types: ['string', 'string', 'currency', 'currency', 'date'],
    },
    {
      id: 'workersCompLossHistoryExcessOf',
      name: 'Loss History Excess of $100k',
      columns: ['DOL', 'Total Incurred', 'Status', 'Description'],
      types: ['date', 'currency', 'string', 'string'],
    },
  ],
  'Cyber Liability': [
    ExcessLiabilitySUL,
    { name: 'Underwriting Information' },
    ExcessGeneralLiabilityLossHistory,
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    ExcessLiabilityLargeLossHistoryExcessOf,
  ],
  'Directors & Officers Liability': [
    ExcessLiabilitySUL,
    { name: 'Underwriting Information' },
    ExcessGeneralLiabilityLossHistory,
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    ExcessLiabilityLargeLossHistoryExcessOf,
  ],
  'Errors & Omissions Liability': [
    ExcessLiabilitySUL,
    { name: 'Underwriting Information' },
    ExcessGeneralLiabilityLossHistory,
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    ExcessLiabilityLargeLossHistoryExcessOf,
  ],
  'Miscellaneous Liability': [
    ExcessLiabilitySUL,
    { name: 'Underwriting Information' },
    ExcessGeneralLiabilityLossHistory,
    {
      id: 'excessLiabilityExcessOf',
      name: 'Loss History Excess of ',
    },
    ExcessLiabilityLargeLossHistoryExcessOf,
  ],
  cert: [
    {
      id: 'certScheduleOfUnderlyingLimits',
      name: 'Underlying Insurance',
      columns: ['LOB', 'Limits', 'Carrier'],
      columnWidths: [0.25, 0.25, 0.5],
      types: ['string', 'string', 'string'],
    },
  ],
}

export function getNonEmptyHeaders(data: any[], defId: string): string[] {
  const headerExclusions = (defId === 'excessLiabilityYearEstimates' || defId === 'generalLiabilityHistoricalExposure' || defId === 'excessLiabilityHistoricalInformation' || defId === 'autoLiabilityHistoricalExposure')
    ? []
    : ['Description', 'Units', 'Mileage', 'Other', 'Total Paid', 'Total Incurred', 'Carrier']

  const headers = new Set<string>()
  data.forEach(obj => {
    for (const [key, value] of Object.entries(obj)) {
      if (headerExclusions.includes(key) || (value !== '' && typeof value !== 'undefined' && value !== null)) {
        headers.add(key)
      }
    }
  })
  return Array.from(headers)
}

export function buildFacExportQtcUnderwriting(
  ordinal: number,
  { fieldMap, currencySymbol }: FacExportData,
  formID: FacFormID,
  facUnderwritingVisibility?: FacUnderwritingVisibility
) {
  const coverage =
    formID === 'certificate' || formID === 'binder'
      ? 'cert'
      : (fieldMap.get('coverageReinsured') as FacCoverageType)
  const rawUnderwriting: string =
    fieldMap.get('underwritingInformation') ?? '{}'
  let noLargeLosses = true

  if (!coverage) {
    return []
  }

  let underwritingMap = new Map<string, UnderwritingValue>()
  if (rawUnderwriting) {
    const underwriting = JSON.parse(rawUnderwriting) as UnderwritingDict
    underwritingMap = Object.keys(underwriting).reduce(
      (acc, key) => acc.set(key, underwriting[key]),
      underwritingMap
    )
  }

  function getName(d: UnderwritingDefinition): string {
    if (d.ordinal) {
      return `${ordinal++}. ${d.name}`
    }
    return `${d.name}`
  }

  const getValue = getUnderwritingValue(underwritingMap, currencySymbol)
  const getRows = getUnderwritingRows(underwritingMap)

  const defs = DEFS[coverage] ?? []
  if (coverage !== 'cert') {
    const filteredDefs = defs.filter(x => x.name.includes('Loss History Excess of '))
    const dname = filteredDefs.length > 0 ? filteredDefs[0].id : null
    if (dname) {
      noLargeLosses =
        (JSON.parse(rawUnderwriting) as UnderwritingDict).noLargeLosses &&
        (
          JSON.parse(rawUnderwriting) as UnderwritingDict
        ).noLargeLosses?.toString() === 'true'
          ? true
          : false
      defs.filter(x => x.name.includes('Loss History Excess of '))[0].name = (
        JSON.parse(rawUnderwriting) as UnderwritingDict
      )[dname]
        ? 'Loss History Excess of ' +
          (JSON.parse(rawUnderwriting) as UnderwritingDict)[dname]?.toString()
        : 'Loss History Excess of '
    }
  }

  const fields = defs.reduce((acc, d, defIndex, arr) => {
    const nextDef = arr[defIndex + 1]
    const bigMargin = isTable(nextDef) || defIndex === arr.length - 1
    const marginBottom = bigMargin ? 6 : 4
    const name = getName(d)
    const cols = d.columns
    let fieldShown = true
    if (name === 'noLargeLosses') {
      fieldShown = false
    }
    if (facUnderwritingVisibility) {
      Object.keys(facUnderwritingVisibility).forEach(key => {
        if (
          facUnderwritingVisibility[key as keyof FacUnderwritingVisibility]?.visibility ||
          facUnderwritingVisibility[key as keyof FacUnderwritingVisibility]?.name !== name
        ) {
          fieldShown = true && fieldShown
        } else {
          fieldShown = false
        }
      })
      // Loss History Excess of overrides
      if ((d.id === 'autoLiabilityExcessOf' || d.id === 'autoLiabilityLossHistoryExcessOf') && facUnderwritingVisibility?.autoLiabilityExcessOf && !facUnderwritingVisibility?.autoLiabilityExcessOf?.visibility) {
        fieldShown = false
      }
      else if ((d.id === 'excessLiabilityExcessOf' || d.id === 'excessLiabilityLargeLossHistoryExcessOf') && facUnderwritingVisibility?.excessLiabilityExcessOf && !facUnderwritingVisibility?.excessLiabilityExcessOf?.visibility) {
        fieldShown = false
      }
      else if ((d.id === 'generalLiabilityExcessOf' || d.id === 'generalLiabilityLossHistoryExcessOf') && facUnderwritingVisibility?.generalLiabilityExcessOf && !facUnderwritingVisibility?.generalLiabilityExcessOf?.visibility) {
        fieldShown = false
      }
    }

    const isLossHistoryExcessOf = d?.id
      ? [
          'excessLiabilityLargeLossHistoryExcessOf',
          'generalLiabilityLossHistoryExcessOf',
          'autoLiabilityLossHistoryExcessOf',
        ].indexOf(d.id) > -1
      : false
    if (
      fieldShown &&
      d.id &&
      ((cols && cols.length > 0 && getRows(d.id)?.length > 0) ||
        getValue(d.id) ||
        isLossHistoryExcessOf)
    ) {
      if (d.types && cols) {
        const colHeads = cols?.slice()
        let rows = getRows(d.id)
        // Print table name, column header, and rows
        if (rows?.length > 0) {          
          if (d.id === 'excessLiabilityExposures' || d.id === 'generalLiabilitySalesBreakout') {
            rows = rows.map(row => {
              const newRow: any = {}
              colHeads.forEach(col => {
                newRow[col] = row[col]
              })
              return newRow
            })
          }
          if (name === 'Auto Historical Exposures') {
            let customOtherHeader = 'Other'
            const otherHeader = rows.map(row => Object.keys(row).filter(a => a !== 'Year' && a !== 'Units' && a !== 'Mileage')).flat()
            if (otherHeader && otherHeader.length > 0) {
              customOtherHeader = otherHeader[otherHeader.length - 1]
            }
            rows.forEach(row => {
              if ((row.Units || row.Other) && !row.Mileage) {
                row.Mileage = ''
              }
              if ((row.Units || row.Mileage) && !row[customOtherHeader]) {
                row[customOtherHeader] = ''
              }
            })
            rows = removeEmptyRows(rows, ['Year'])
          }
          if (d.id?.includes('FleetBreakout')) {
            rows = rows.filter(obj => {
              return !(obj.Local === '' && obj.Intermediate === '' && obj['Long Distance'] === '' && obj.Total?.toString() === '0')
            }).map(obj => {
              const hasBlankValues = (obj.Local === '' && obj.Intermediate === '' && obj['Long Distance'] === '')
              const hasZeroValues = ((obj.Local && obj.Local?.toString() === '0') && (obj.Intermediate && obj.Intermediate?.toString() === '0') && (obj['Long Distance'] && obj['Long Distance']?.toString() === '0'))

              if ((hasBlankValues || hasZeroValues) && obj.Total?.toString() !== '0') {
                return {
                  'Vehicle Type': obj['Vehicle Type'],
                  Total: obj.Total
                }
              } else if (!(hasBlankValues && hasZeroValues)) {
                return {
                  'Vehicle Type': obj['Vehicle Type'],
                  Local: obj.Local,
                  Intermediate: obj.Intermediate,
                  'Long Distance': obj['Long Distance'],
                  Total: obj.Total
                }
              }
            }) as Record<string, string>[]
          }
          const allCols = getNonEmptyHeaders(rows, d.id)
            .flat()
            .reduce(
              (uq: string[], val: string) =>
                uq.includes(val) ? uq : [...uq, val],
              []
            )
          if (d.id === 'excessLiabilityYearEstimates' || d.id === 'generalLiabilityHistoricalExposure') {
            rows = rows.map(row => {
              const newRow: any = {}
              allCols.forEach(col => {
                if (!row[col])
                  row[col] = ''
                newRow[col] = row[col] === '' ? ' ' : row[col]
              })
              return newRow
            })
            rows = removeEmptyRows(rows, ['Year'])
          }
          if (name === 'Auto Historical Exposures') {
            rows = rows.map(row => {
              const newRow: any = {}
              allCols.forEach(col => {
                newRow[col] = row[col] === '' ? ' ' : row[col]
              })
              return newRow
            })
          }
          if (formID !== 'submission' && (d.id === 'excessLiabilityScheduleOfUnderlyingLimits' || d.id === 'certScheduleOfUnderlyingLimits')) {
            rows.map(row => {
              if (row['Premium'])
                delete row['Premium']
              return row
            })

            const premiumIndex = allCols.indexOf('Premium')
            if (premiumIndex > -1) {
              allCols.splice(premiumIndex, 1)
            }
          }
          const allowOnlyYearInRow = formID === "submission" && ["lossHistory",
                                "generalLiabilityLossHistory",
                                "autoLiabilityLossHistory",
                                "excessLiabilityLossHistory",
                                "excessAutoLiabilityLossHistory",
                                "excessGeneralLiabilityLossHistory"].includes(d.id)
          const allowOnlyLobInRow = (formID === "submission" && d.id === "excessLiabilityLargeLossHistoryExcessOf") || d.id === "excessLiabilityScheduleOfUnderlyingLimits"
          rows = rows
            .map(row => {
              if (
                Object.keys(row)
                  .map(k =>
                    (k === 'Description' || k === 'Units' || k === 'Mileage' || k === 'Other' || (k === 'Claims' && allCols.includes("Claims")) || k === 'Total Incurred' || k === 'Carrier' || k === 'Total Paid'
                      || (allowOnlyLobInRow && ((k === 'Premium' && allCols.includes('Premium')) || (k === 'Limits' && allCols.includes('Limits')) || (k === "DOL" && allCols.includes("DOL")) || (k === "Status" && allCols.includes("Status")) || (k === "See Attached U/L Schedule" && allCols.includes("See Attached U/L Schedule"))))
                      || (allowOnlyYearInRow && (k === "Val Date" && allCols.includes("Val Date")))
                    )
                    && row[k] === '' ? ' ' : (k === 'Local' || k === 'Intermediate' || k === 'Long Distance') && row[k] === '' ? '0' : row[k]
                  )
                  .filter(
                    r => !(r === '' || typeof r === 'undefined' || r === null)
                  ).length === allCols.length
              ) {
                let newRow = row
                if (row?.Description === '') {
                  newRow = { ...newRow, Description: ' ' }
                }
                if (row?.Units === '') {
                  newRow = { ...newRow, Units: ' ' }
                }
                if (row?.Mileage === '') {
                  newRow = { ...newRow, Mileage: ' ' }
                }
                if (row?.Other === '') {
                  newRow = { ...newRow, Other: ' ' }
                }
                if (row?.Local === '') {
                  newRow = { ...newRow, Local: '0' }
                }
                if (row?.Intermediate === '') {
                  newRow = { ...newRow, Intermediate: '0' }
                }
                if (row?.Carrier === '') {
                  newRow = { ...newRow, Carrier: ' ' }
                }
                if (row && row['Total Incurred'] === '') {
                  newRow = { ...newRow, 'Total Incurred': '$0' }
                }
                if (row && row['Long Distance'] === '') {
                  newRow = { ...newRow, 'Long Distance': '0' }
                }
                return newRow
              }
            })
            .filter(Boolean) as Record<string, string>[]

          allCols.forEach(col => {
            const hasEmptyColumn =
              rows
                .map(r =>
                  (col === 'Description' || col === 'Units' || col === 'Mileage' || col === 'Other') && r[col] === '' ? ' ' : (col === 'Local' || col === 'Intermediate' || col === 'Long Distance') && r[col] === '' ? '0' : r[col]
                )
                .filter(r => r === '' || typeof r === 'undefined' || r === null)
                .length === rows.length
            const valuesAllFalse =
              col === 'See Attached U/L Schedule' &&
              rows.map(r => r[col]).filter(r => r?.toString() === 'false')
                .length === rows.length
            if (hasEmptyColumn || valuesAllFalse) {
              colHeads?.splice(colHeads?.indexOf(col), 1)
              rows = rows.map(row => {
                const { [col]: remove, ...rest } = row
                return rest
              })
            }
          })
        }

        if (name !== '' && rows?.length > 0) {
          acc.push([{ type: 'text', value: name, fontStyle: 'bold' }])
        }
        
        if (
          (d.id === 'excessLiabilityLargeLossHistoryExcessOf' ||
            d.id === 'generalLiabilityLossHistoryExcessOf' ||
            d.id === 'autoLiabilityLossHistoryExcessOf') &&
          noLargeLosses
        ) {
          const value = 'No Large Losses'
          acc.push([{ type: 'text', value, fontStyle: 'normal', marginBottom }])
        } else if (rows.length === 0 || cols.length === 0) {
          // Don't display anything for empty table
        } else if (rows.length > 0) {
          if (rows[0]?.DOL && !noLargeLosses && fieldShown) {
            const index = colHeads?.indexOf('No Large Losses')
            if (index && index > -1) {
              colHeads?.splice(index, 1)
            }
          } else if (
            rows[0]['See Attached U/L Schedule'] !== undefined &&
            (rows[0]['See Attached U/L Schedule']?.toString() === 'false' ||
              rows[0]['See Attached U/L Schedule'] === '')
          ) {
            const index = colHeads?.indexOf('See Attached U/L Schedule')
            if (index && index > -1) {
              colHeads?.splice(index, 1)
            }
          }
          if (
            d.id !== 'autoLiabilityFleetBreakout' &&
            d.id !== 'excessAutoLiabilityFleetBreakout'
          ) {
            Object.keys(rows[0]).forEach(k => {
              if (!colHeads?.includes(k)) {
                colHeads?.push(k)
              }
            })
            const newColHeads = colHeads?.map(x => x)
            newColHeads?.forEach(e => {
              if (
                typeof rows[0][e] === 'undefined' ||
                rows.map(r => r[e]).filter(r => r === '').length === rows.length
              ) {
                colHeads?.splice(colHeads.indexOf(e), 1)
              }
            })
          }
          acc.push([
            {
              ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
              type: 'text',
              colWidths: getColWidths(d, colHeads),
              value: (d.id?.includes('FleetBreakout') ? Object.keys(rows[0]) : colHeads),
            },
            ...rows
              .filter(
                row =>
                  !row.Total ||
                  row.Total !== '0' ||
                  (row.Radius && row.Radius !== '')
              )
              .map((row, i) => {
                const f: FacExportField = {
                  type: 'text' as const,
                  fontStyle: 'normal',
                  marginBottom: i === rows.length - 1 ? marginBottom + 2 : 2,
                  value: (d.id?.includes('FleetBreakout') ? Object.keys(row) : colHeads).map(c => row[c] ?? ''),
                }
                return f
              }),
          ])
        } else {
          acc.push([
            {
              ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
              type: 'text',
              colWidths: getColWidths(d, colHeads),
              value: colHeads,
            },
            ...rows.map((row, i) => {
              const f: FacExportField = {
                type: 'text' as const,
                fontStyle: 'normal',
                marginBottom: i === rows.length - 1 ? marginBottom + 2 : 2,
                value: colHeads.map(c => row[c] ?? ''),
              }
              return f
            }),
          ])
        }
      } else if (
        d.id &&
        d.id !== 'excessLiabilityExcessOf' &&
        d.id !== 'generalLiabilityExcessOf' &&
        d.id !== 'autoLiabilityExcessOf'
      ) {
        // Print label & value field
        const value = getValue(d.id)
        acc.push([{ type: 'field', label: name, value, marginBottom: 2 }])
      } else {
        // Print label
        const value = name
        acc.push([{ type: 'text', value, fontStyle: 'bold', marginBottom: 2 }])
      }
    }
    return acc
  }, [] as FacExportField[][])

  return fields
}

function isTable(d?: UnderwritingDefinition) {
  return d != null && d.id && d.columns && d.types
}

export function getColWidths(def: UnderwritingDefinition, columns: string[]): number[] {
  if (columns.length < 1) {
    return [1]
  }
  if (def.columnWidths?.length && def.columns?.toString() === columns?.toString()) {
    return def.columnWidths
  }

  const w = Number((columns.length < 5 ? (columns.length * 0.18) / columns.length : 1 / columns.length).toFixed(2))
  let columnSizes: number[] = []
  columnSizes = columns.includes('Description')
    ? columns.map((c) => c === 'Description' ? 1 - w * (columns.length - 1) : w)
    : columns.includes('Vehicle Type')
      ? columns.map((c) => (c === 'Vehicle Type' ? 0.2 : w))
      : columns.map(() => w)
  return columnSizes
}

const getUnderwritingValue = curry(
  (
    underwritingMap: Map<string, UnderwritingValue>,
    currencySymbol: string,
    id: string
  ): string => {
    if (id === CUSTOM_ID_TOTAL_PAYROLL) {
      let total = 0
      const getValue = getUnderwritingValue(underwritingMap, currencySymbol)
      const adc = getValue('workersCompPayrollADC') || '0'
      const rdc = getValue('workersCompPayrollRDC') || '0'
      total += parseFloat(stripNonNumeric(adc))
      total += parseFloat(stripNonNumeric(rdc))
      return `${currencySymbol}${formatThousandsCommas(total)}`
    }
    const value = underwritingMap.get(id)?.toString() ?? ''
    if (typeof value !== 'string') {
      throw new Error(`Malformed Fac QTC Underwriting value '${id}'`)
    }
    return value
  }
)

const getUnderwritingRows = curry(
  (
    underwritingMap: Map<string, UnderwritingValue>,
    id: string
  ): Record<string, string>[] => {
    const value = underwritingMap.get(id) ?? []
    if (typeof value === 'string') {
      throw new Error(`Malformed Fac QTC Underwriting rows '${id}'`)
    }
    return value
  }
)

export function removeEmptyRows(rowsIn: readonly Record<string, string>[], ignoreColumns: string[]): Record<string, string>[] {
  const rowsOut: Record<string, string>[] = []
  rowsIn?.forEach(record => {
    const rowValues = Object.getOwnPropertyNames(record).filter(k => !ignoreColumns.includes(k)).map((k) => record[k])
    if (rowValues.some(v => v?.trim()?.length > 0))  {
      rowsOut.push(record)
    }
  })
  return rowsOut
}

