
import { Injectable } from '@angular/core'
import {
  PdfField,
  PdfFieldRow,
  PdfRow,
  PdfText,
  PdfTextRow
} from '../pdf/pdf-generator.interface'
import {
  CoverageReinsured,
  FacDealType,
  FacExportData,
  FacExportQtcData,
  FacFormID,
  FieldConfig,
  FieldTypes,
  FormLayerResponse,
  LayerResponseGroup,
  MGADataArray
} from '../../models'
import {
  facFormatCurrency,
  facFormatPercent, formatDateString, formatLimitsAsArray,
  formatRetentionAsArray, formatValue,
  stripNonNumeric
} from '../../formatters'
import { buildReinsuredLayersFields } from './utils'
import {
  buildLayerGroups,
  getPremiumRIString
} from '../../utils'
import { getCurrencySymbol } from '@angular/common'
import { LabelConstants } from '@constants'

export abstract class IReportModelBuilder {
  abstract addFieldRowFromConfig(fieldId: string): IReportModelBuilder
  abstract addFieldRow(fieldId: string, label: string): IReportModelBuilder
  abstract addMinimumAndDeposit(): IReportModelBuilder
  abstract addPolicyRetroDate(): IReportModelBuilder
  abstract addPolicyType(): IReportModelBuilder
  abstract addNoteableTerms(): IReportModelBuilder
  abstract addProgramStructure(): IReportModelBuilder
  abstract addCompanyRetention(): IReportModelBuilder
  abstract addReinsuranceLimits(): IReportModelBuilder
  abstract addScheduleOfUnderlyingLimits(): IReportModelBuilder
  abstract addReinsurancePremiums(): IReportModelBuilder
  abstract addReinsurersTable(): IReportModelBuilder
  abstract addAdditionalResponsesTable(): IReportModelBuilder

  abstract build(): PdfRow[]
}

@Injectable({ providedIn: 'root' })
export class ReportModelBuilderFactoryService {
  create(data: FacExportData): IReportModelBuilder {
    return new DefaultReportModelBuilder(data)
  }
}

interface ReportModelBuilderContext {
  readonly fieldConfigArrayMap: ReadonlyMap<string, Readonly<FieldConfig>[]>
  readonly fieldConfigMap: ReadonlyMap<string, Readonly<FieldConfig>>
  readonly fieldValuesMap: ReadonlyMap<string, string>,
  readonly hiddenFields: ReadonlyArray<string>
  readonly dealType: FacDealType
  readonly data: FacExportData
}

export class DefaultReportModelBuilder implements IReportModelBuilder {
  private ctx: ReportModelBuilderContext
  private modelFieldRows: PdfRow[] = []

  constructor(data: FacExportData) {
    const fields: FieldConfig[][] = data.fieldConfigs.length > 0 ? data.fieldConfigs[0].fields : []
    this.ctx = {
      fieldConfigArrayMap: new Map(fields.map((fa: FieldConfig[]) => [fa[0].id, fa])),
      fieldConfigMap: new Map(fields.flat().map((f: FieldConfig) => [f.id, f])),
      fieldValuesMap: data.fieldMap,
      hiddenFields: data.hiddenFields ?? [],
      dealType: data.dealType,
      data
    }
  }

  build(): PdfRow[] {
    return this.modelFieldRows
  }

  addFieldRowFromConfig(fieldId: string): IReportModelBuilder {
    const configRow: ReadonlyArray<Readonly<FieldConfig>> = this.getFieldConfigRow(fieldId)
    const fields: PdfField[] = []
    configRow.forEach((c: FieldConfig) => {
      let valueString: string = this.getFieldValue(c.id)
      if (c.inputType === 'percentage') {
        valueString = formatValue(valueString, 2)
      }
      if (!this.isHiddenOrEmpty(c.id)) {
        fields.push(this.buildExportField(c.id, c.label ?? '', valueString))
      }
    })
    if (fields.length > 0) {
      this.modelFieldRows.push(this.createExportFieldRow(fields))
    }
    return this
  }

  addFieldRow(fieldId: string, label: string): IReportModelBuilder {
    let valueString: string = this.getFieldValue(fieldId)
    if (valueString.endsWith('%')) {
      valueString = formatValue(valueString, 2)
    }
    if (this.isHiddenOrEmpty(fieldId)) {
      return this
    }
    const fields = [this.buildExportField(fieldId, label, valueString)]
    this.modelFieldRows.push(this.createExportFieldRow(fields))
    return this
  }

  addMinimumAndDeposit(): IReportModelBuilder {
    const minimumValue = this.getFieldValue(FieldTypes.minimum)
    const depositValue = this.getFieldValue(FieldTypes.deposit)

    if (!this.isHiddenOrEmpty(FieldTypes.minimum) && !this.isHiddenOrEmpty(FieldTypes.deposit)) {
      const valueText = `${minimumValue} Minimum; ${depositValue} Deposit`
      const row = this.createExportFieldRow([this.buildExportField('minimumAndDeposit', 'Minimum & Deposit', valueText)])
      this.modelFieldRows.push(row)
    }
    else  if (!this.isHiddenOrEmpty(FieldTypes.minimum) && this.isHiddenOrEmpty(FieldTypes.deposit)) {
      const row = this.createExportFieldRow([this.buildExportField(FieldTypes.minimum, 'Minimum', minimumValue)])
      this.modelFieldRows.push(row)
    }
    else  if (this.isHiddenOrEmpty(FieldTypes.minimum) && !this.isHiddenOrEmpty(FieldTypes.deposit)) {
      const row = this.createExportFieldRow([this.buildExportField(FieldTypes.deposit, 'Deposit', depositValue)])
      this.modelFieldRows.push(row)
    }
    return this
  }

  addProgramStructure(): IReportModelBuilder {
    const hidden = this.isUmbrella() || this.isHiddenOrEmpty(FieldTypes.programStructure)
    if (hidden) {
      return this
    }
    const programStructureField = this.getFieldConfig(FieldTypes.programStructure)
    const programStructureValue: string = this.getFieldValue(FieldTypes.programStructure)
    const retentionAmountValue: string = this.getFieldValue(FieldTypes.retentionAmount)

    const label: string = programStructureField?.label ?? ''
    const valueText = programStructureValue === 'Guaranteed Cost' ? programStructureValue : `${programStructureValue}: ${retentionAmountValue}`
    const exportField: PdfField = this.buildExportField(FieldTypes.programStructure, label, valueText)
    this.modelFieldRows.push(this.createExportFieldRow([exportField]))
    return this
  }

  addPolicyType(): IReportModelBuilder {
    if (!this.isCyberish()) {
      return this
    }
    const policyTypeField = this.getFieldConfig(FieldTypes.policyType)
    const policyTypeValue: string = this.getFieldValue(FieldTypes.policyType)

    const label: string = policyTypeField?.label ?? ''
    const exportField: PdfField = this.buildExportField(FieldTypes.policyType, label, policyTypeValue)
    this.modelFieldRows.push(this.createExportFieldRow([exportField]))
    return this
  }

  addPolicyRetroDate(): IReportModelBuilder {
    if (!this.isClaimsMade()) {
      return this
    }
    const exportFields = []
    const policyRetroCommentValue: string = this.getFieldValue(FieldTypes.policyRetroComment)

    const policyRetroDateField = this.getFieldConfig(FieldTypes.policyRetroDate)
    const policyRetroDateValue: string = this.getFieldValue(FieldTypes.policyRetroDate)
    const exportPolicyRetroDate: PdfField = this.buildExportField(FieldTypes.policyRetroDate, policyRetroDateField?.label ?? '', policyRetroCommentValue !== '' ? policyRetroCommentValue : policyRetroDateValue)
    exportFields.push(exportPolicyRetroDate)

    const reinsuranceRetroDateField = this.getFieldConfig('ReinsuranceRetroDate')
    const reinsuranceRetroDateValue: string = this.getFieldValue('ReinsuranceRetroDate')
    const exportReinsuranceRetroDate: PdfField = this.buildExportField('ReinsuranceRetroDate', reinsuranceRetroDateField?.label ?? '', formatDateString(reinsuranceRetroDateValue))
    if (reinsuranceRetroDateValue !== '') {
      exportFields.push(exportReinsuranceRetroDate)
    }

    this.modelFieldRows.push(this.createExportFieldRow(exportFields))
    return this
  }

  addNoteableTerms(): IReportModelBuilder {
    if (!this.isCyberish()) {
      return this
    }
    const notableTermsField = this.getFieldConfig(FieldTypes.notableTerms)
    const notableTermsValue: string = this.getFieldValue(FieldTypes.notableTerms)

    const label: string = notableTermsField?.label ?? ''
    const exportField: PdfField = this.buildExportField(FieldTypes.notableTerms, label, notableTermsValue)
    this.modelFieldRows.push(this.createExportFieldRow([exportField]))
    return this
  }

  addCompanyRetention(): IReportModelBuilder {
    const retentionLines = formatRetentionAsArray(this.ctx.fieldValuesMap, this.ctx.dealType)
    const unformattedLines = retentionLines.map(v => v.replace(/<b>/g, '').replace(/<\/b>?/gm, ''))
    const retentionField: PdfField = {
      hidden: false,
      label: 'Add Company Retention Net and/or Treaty',
      type: 'field',
      value: unformattedLines
    }
    this.modelFieldRows.push(this.createExportFieldRow([retentionField]))
    return this
  }

  addReinsuranceLimits(): IReportModelBuilder {
    const limitsLines = formatLimitsAsArray(this.ctx.fieldValuesMap, this.ctx.dealType, undefined)
    const unformattedLines = limitsLines.map(v => v.replace(/<b>/g, '').replace(/<\/b>?/gm, ''))
    const limitsField: PdfField = {
      hidden: false,
      label: 'Reinsurance Limit(s)',
      type: 'field',
      value: unformattedLines
    }
    this.modelFieldRows.push(this.createExportFieldRow([limitsField]))
    return this
  }

  addScheduleOfUnderlyingLimits(): IReportModelBuilder {
    // For Umbrella deals only, if not an Umbrella do not display this field
    if (!this.isUmbrella() || this.isHidden('excessLiabilityScheduleOfUnderlyingLimits')) {
      return this
    }

    const pdfTableRow: PdfTextRow | null = this.buildUnderlyingLimitsTable(this.ctx.fieldValuesMap)
    if (!pdfTableRow) {
      return this
    }

    const pdfTitleRow: PdfTextRow = this.createTableTitleRow('Underlying Insurance')
    this.modelFieldRows.push(pdfTitleRow, pdfTableRow)
    return this
  }

  private buildUnderlyingLimitsTable(fieldValuesMap: ReadonlyMap<string, string>): PdfTextRow | null {
    const rawUnderwriting = fieldValuesMap.get('underwritingInformation')
    if (!rawUnderwriting) {
      return null
    }
    let underwriting
    try {
      underwriting = JSON.parse(rawUnderwriting)
    } catch (e) {
      console.error('Error parsing underwriting information:', e)
      return null
    }
    const rows: any[] = underwriting.certScheduleOfUnderlyingLimits // for submission form use - underwriting.excessLiabilityScheduleOfUnderlyingLimits
    if (!rows || !Array.isArray(rows) || rows.length === 0) {
      return null
    }

    const columns = ['LOB', 'Limits', 'Carrier']
    const colWidths = [0.25, 0.25, 0.5]
    const pdfTextItems: PdfText[] = []
    const pdfHeaderText: PdfText = {
      gutter: 1,
      fontSize: 9,
      fontStyle: 'bold',
      lineHeight: 3.5,
      marginBottom: 3,
      asTable: true,
      type: 'text',
      colWidths,
      value: columns,
    }
    pdfTextItems.push(pdfHeaderText)

    const pdfRows: PdfText[] = rows.map((row: any, index: number) => {
      return {
        type: 'text',
        fontStyle: 'normal',
        marginBottom: index === rows.length - 1 ? 6 : 2,
        value: [row.LOB, row.Limits === '' ? ' ' : row.Limits, row.Carrier === '' ? ' ' : row.Carrier]
      } as PdfText
    })
    pdfTextItems.push(...pdfRows)

    return {type: 'text', items: pdfTextItems}
  }

  addReinsurancePremiums(): IReportModelBuilder {
    const layerFields = buildReinsuredLayersFields(
      this.ctx.data,
      {},
      this.ctx.data.formCategory as FacFormID
    )
    if (!layerFields || layerFields.length < 3) {
      return this
    }
    const layerField = layerFields[2] as any
    const premiumsField: PdfField = {
      id: 'customReinsurancePremiums',
      hidden: false,
      label: layerField.label,
      type: layerField.type,
      value: layerField.value
    }
    this.modelFieldRows.push(this.createExportFieldRow([premiumsField]))
    return this
  }

  private isUmbrella() {
    const coverageReinsured = this.getFieldValue(FieldTypes.coverageReinsured) ?? ''
    const umbrellaList = [
      CoverageReinsured.umbrella.toString(),
      CoverageReinsured.umbrellaGeneral.toString(),
      CoverageReinsured.umbrellaAuto.toString(),
      CoverageReinsured.excessAuto.toString(),
      CoverageReinsured.cyber.toString(),
      CoverageReinsured.directorsOfficers.toString(),
      CoverageReinsured.errorsOmissions.toString(),
      CoverageReinsured.miscellaneous.toString(),
    ]
    return umbrellaList.includes(coverageReinsured)
  }

  private isCyberish() {
    const coverageReinsured = this.getFieldValue(FieldTypes.coverageReinsured) ?? ''
    const cyberishList = [CoverageReinsured.cyber.toString(), CoverageReinsured.directorsOfficers.toString(), CoverageReinsured.errorsOmissions.toString(), CoverageReinsured.miscellaneous.toString()]
    return cyberishList.includes(coverageReinsured)
  }

  private isClaimsMade() {
    const coverageBasis = this.getFieldValue(FieldTypes.coverageBasis) ?? ''
    return coverageBasis === 'Claims Made'
  }

  private createTableTitleRow(titleText: string): PdfTextRow {
    return {
      type: 'text',
      items: [{type: 'text', fontStyle: 'bold', value: titleText}]
    }
  }

  private createExportFieldRow(fields: PdfField[]): PdfFieldRow {
    return { type: 'field', items: [...fields] }
  }

  private buildExportField(id: string, label: string, value: string | string[]): PdfField {
    return { id, type: 'field', label, value }
  }

  private getFieldValue(fieldId: string): string {
    return this.ctx.fieldValuesMap.get(fieldId) ?? ''
  }

  private getFieldConfig(fieldId: string): FieldConfig | undefined {
    return this.ctx.fieldConfigMap.get(fieldId)
  }

  private isHiddenOrEmpty(fieldId: string): boolean {
    const valueString: string = this.getFieldValue(fieldId)
    return this.isHidden(fieldId) || valueString.trim().length === 0
  }

  private isHidden(fieldId: string): boolean {
    return this.ctx.hiddenFields.includes(fieldId)
  }

  private getFieldConfigRow(fieldId: string): ReadonlyArray<Readonly<FieldConfig>> {
    return this.ctx.fieldConfigArrayMap.get(fieldId) ?? []
  }

  addReinsurersTable(): IReportModelBuilder {
    if (this.ctx.dealType !== 'casualty' && this.ctx.data.formCategory !== 'qtc') {
      return this
    }
    // Only Auto, GL, and Umbrella for now
    const coverageReinsured = this.getFieldValue(FieldTypes.coverageReinsured) ?? ''
    if (coverageReinsured !== CoverageReinsured.auto.toString() &&
        coverageReinsured !== CoverageReinsured.general.toString() &&
        coverageReinsured !== CoverageReinsured.umbrella.toString() &&
        coverageReinsured !== CoverageReinsured.umbrellaGeneral.toString() &&
        coverageReinsured !== CoverageReinsured.umbrellaAuto.toString() &&
        coverageReinsured !== CoverageReinsured.excessAuto.toString() &&
        coverageReinsured !== CoverageReinsured.cyber.toString() &&
        coverageReinsured !== CoverageReinsured.directorsOfficers.toString() &&
        coverageReinsured !== CoverageReinsured.errorsOmissions.toString() &&
        coverageReinsured !== CoverageReinsured.miscellaneous.toString())
    {
      return this
    }

    const qtcData = this.ctx.data as FacExportQtcData
    if (!qtcData.marketResponses || qtcData.marketResponses.length === 0) {
      return this
    }

    const currency = qtcData.fieldMap.get(FieldTypes.currency) ?? 'USD'
    const filteredMarketResponses = qtcData.marketResponses.filter(r => r.Quoted && !r.Declined)
    const pdfTableRow: PdfTextRow | null = this.buildQtcReinsurersTable(filteredMarketResponses, currency)
    if (!pdfTableRow) {
      return this
    }
    this.modelFieldRows.push(pdfTableRow)
    return this
  }

  addAdditionalResponsesTable(): IReportModelBuilder {
    if (this.ctx.dealType !== 'casualty' && this.ctx.data.formCategory !== 'qtc') {
      return this
    }
    // Only Auto, GL, and Umbrella for now
    const coverageReinsured = this.getFieldValue(FieldTypes.coverageReinsured) ?? ''
    if (coverageReinsured !== CoverageReinsured.auto.toString() &&
      coverageReinsured !== CoverageReinsured.general.toString() &&
      coverageReinsured !== CoverageReinsured.umbrella.toString() &&
      coverageReinsured !== CoverageReinsured.umbrellaGeneral.toString() &&
      coverageReinsured !== CoverageReinsured.umbrellaAuto.toString() &&
      coverageReinsured !== CoverageReinsured.excessAuto.toString() &&
      coverageReinsured !== CoverageReinsured.cyber.toString() &&
      coverageReinsured !== CoverageReinsured.directorsOfficers.toString() &&
      coverageReinsured !== CoverageReinsured.errorsOmissions.toString() &&
      coverageReinsured !== CoverageReinsured.miscellaneous.toString()) {
      return this
    }

    const qtcData = this.ctx.data as FacExportQtcData
    if (!qtcData.marketResponses || qtcData.marketResponses.length === 0) {
      return this
    }

    const declinedResponses = qtcData.marketResponses.filter(r => r.Declined && r.declinedReason?.trim().length > 0)
    if (declinedResponses.length === 0) {
      return this
    }
    const pdfTableRow: PdfTextRow | null = this.buildQtcAdditionalResponsesTable(declinedResponses)
    if (!pdfTableRow) {
      return this
    }
    const pdfTitleRow: PdfTextRow = this.createTableTitleRow('Additional Responses')
    this.modelFieldRows.push(pdfTitleRow, pdfTableRow)
    return this
  }

  private buildQtcReinsurersTable(marketResponses: FormLayerResponse[], currency: string): PdfTextRow | null {

    const columns = ['Reinsurer', 'Participation\nAmount', 'Participation\nPercentage',  'Gross\nPremium', 'Net\nPremium']
    const colWidths = [0.35, 0.17, 0.15, 0.15, 0.15]
    const pdfTextItems: PdfText[] = []
    const pdfHeaderText: PdfText = {
      gutter: 1,
      fontSize: 9,
      fontStyle: 'bold',
      lineHeight: 3.5,
      marginBottom: 2,
      asTable: true,
      type: 'text',
      colWidths,
      value: columns,
    }
    pdfTextItems.push(pdfHeaderText)

    const layerGroups: ReadonlyArray<LayerResponseGroup> = buildLayerGroups(marketResponses)
    const numLayers = layerGroups.length
    layerGroups.forEach((group: LayerResponseGroup, index: number) => {
      const bottomMargin = index === numLayers - 1 ? 8 : 4
      const layerGroupRows = this.buildQtcReinsurersLayerGroupRows(group, currency, bottomMargin)
      pdfTextItems.push(...layerGroupRows)
    })
    return {type: 'text', items: pdfTextItems}
  }

  private buildQtcReinsurersLayerGroupRows(group: LayerResponseGroup, currency: string, bottomMargin: number): PdfText[] {
    const pdfRows: PdfText[] = []

    // Layer label
    pdfRows.push({type: 'text', fontStyle: 'bold', value: [group.label, ' ', ' ', ' ', ' '], marginBottom: 2})

    // Layer responses
    group.responses.forEach((response: FormLayerResponse) => {
      const capacity = facFormatCurrency(parseFloat(stripNonNumeric(response.Capacity)))
      const percentageShare = facFormatPercent(parseFloat(stripNonNumeric(response.percentageShare)))
      const grossShare = facFormatCurrency(parseFloat(stripNonNumeric(response.grossShare)))
      const netShare = facFormatCurrency(parseFloat(stripNonNumeric(response.netShare)))
      pdfRows.push({
        type: 'text',
        fontStyle: 'normal',
        value: [response.reinsurer, capacity, percentageShare, grossShare, netShare]
      } as PdfText)

      if (response.mgaArray?.length > 0) {
        const isSingleSyndicate = response.mgaArray?.length === 1
        const mgaRows: PdfText[] = []
        mgaRows.push({
          type: 'text',
          fontStyle: 'italic',
          value: ['On Behalf Of:', ' ', ' ', ' ', ' ']
        } as PdfText)
        response.mgaArray.forEach((mga: MGADataArray) => {

          const behalfOfAmount = isSingleSyndicate ? ' ' : facFormatCurrency(getPremiumRIString(response.Capacity, mga.participation, response.percentageShare))
          const behalfOfPercentage = isSingleSyndicate ? ' ' : facFormatPercent(parseFloat(stripNonNumeric(mga.participation)))
          const behalfOfGross = isSingleSyndicate ? ' ' : facFormatCurrency(getPremiumRIString(response.grossShare, mga.participation, response.percentageShare))
          const behalfOfNet = isSingleSyndicate ? ' ' : facFormatCurrency(getPremiumRIString(response.netShare, mga.participation, response.percentageShare))
          mgaRows.push({
            type: 'text',
            fontStyle: 'normal',
            value: [mga.name, behalfOfAmount, behalfOfPercentage, behalfOfGross, behalfOfNet]
          } as PdfText)
        })
        pdfRows.push(...mgaRows)
      }
    })

    // Totals
    const currencySymbol: string = getCurrencySymbol(currency, 'narrow')
    pdfRows.push({type: 'text', fontStyle: 'bold', value: [
      LabelConstants.Total.toUpperCase(),
      facFormatCurrency(group.totals.totalParticipationAmount ?? 0, currencySymbol),
      facFormatPercent(group.totals.totalParticipationPercentage ?? 0),
      facFormatCurrency(group.totals.totalGross ?? 0, currencySymbol),
      facFormatCurrency(group.totals.totalNet ?? 0, currencySymbol),
      ],
      marginBottom: bottomMargin
    })

    return pdfRows
  }

  private buildQtcAdditionalResponsesTable(declinedResponses: FormLayerResponse[]): PdfTextRow | null {

    const columns = ['Reinsurer', 'Response']
    const colWidths = [0.4, 0.4]
    const pdfTextItems: PdfText[] = []
    const pdfHeaderText: PdfText = {
      gutter: 1,
      fontSize: 9,
      fontStyle: 'bold',
      lineHeight: 3.5,
      marginBottom: 3,
      asTable: true,
      type: 'text',
      colWidths,
      value: columns,
    }
    pdfTextItems.push(pdfHeaderText)

    const rowCount = declinedResponses.length
    const pdfRows: PdfText[] = declinedResponses.map((row: FormLayerResponse, index: number) => {
      return {
        type: 'text',
        fontStyle: 'normal',
        marginBottom: index === rowCount - 1 ? 6 : 2,
        value: [row.reinsurer, row.declinedReason]
      } as PdfText
    })
    pdfTextItems.push(...pdfRows)

    return {type: 'text', items: pdfTextItems}
  }
}
