import {FacData, FacDataVersion} from '../models'
import { isTravelers } from '@deals/utils/getDefaultReinsuranceCertificate'

export function updateFacHeaderFields(headerData: Readonly<FacData>, versionData: Readonly<FacDataVersion>): FacData {
  const updatedHeader: FacData = {
    ...headerData
  }
  const fieldValues = JSON.parse(versionData.fieldValues)
  updatedHeader.allocatedLossAdjustmentExpense = fieldValues.allocatedLossAdjustmentExpense
  updatedHeader.cityName = fieldValues.cityName
  updatedHeader.coverageReinsured = fieldValues.coverageReinsured
  updatedHeader.currency = fieldValues.currency
  // updatedHeader.otherFacultative = fieldValues.otherFacultative
  // if (versionData?.category !== 'responses') {
  //   updatedHeader.policyNumber = fieldValues.policyNumber
  // }
  updatedHeader.policyPeriodFrom = fieldValues.policyPeriodFrom
  updatedHeader.policyPeriodTo = fieldValues.policyPeriodTo
  updatedHeader.quoteNeededBy = fieldValues.quoteNeededBy
  updatedHeader.reinsurancePeriodFrom = fieldValues.reinsurancePeriodFrom
  updatedHeader.reinsurancePeriodTo = fieldValues.reinsurancePeriodTo
  updatedHeader.reinsured = fieldValues.reinsured
  updatedHeader.reinsuredId = fieldValues['reinsured--ID']
  updatedHeader.reinsuredUnderwriter = fieldValues.reinsuredUnderwriter
  updatedHeader.reinsuredUnderwriterId = fieldValues['reinsuredUnderwriter--ID']
  // TODO Fac map renewal field
  // updatedHeader.renewal = fieldValues.xxx
  if (!(isTravelers(updatedHeader.reinsured) && (versionData?.category === 'binder' || versionData?.category === 'certificate'))) {
    updatedHeader.stateName = fieldValues.stateName
    updatedHeader.streetAddress = fieldValues.streetAddress
    updatedHeader.zipCode = fieldValues.zipCode
  }
  if (versionData?.category !== 'binder' && versionData?.category !== 'certificate' && versionData?.category !== 'qtc' && versionData?.category !== 'responses') {
    updatedHeader.name = fieldValues.name
    updatedHeader.typeOfInsurance = fieldValues.typeOfInsurance
    updatedHeader.perilsExcluding = JSON.stringify(fieldValues.excluding)
    updatedHeader.perilsIncluding = JSON.stringify(fieldValues.including)
    if (fieldValues.only && typeof fieldValues.only === 'string') {
      const onlyStripped = fieldValues.only.replace(/\\"/g, '').replace(/\\/g, '')
      try {
        fieldValues.only = JSON.parse(onlyStripped)
      } catch (e) {
        fieldValues.only = onlyStripped
        console.log(e)
      }
    }
    updatedHeader.perilsOnly = JSON.stringify(fieldValues.only)
  }
  if (fieldValues.username && versionData?.category === 'submission') {
    updatedHeader.username = fieldValues.username
  }

  return updatedHeader
}
