import {
  CertificateFields,
  FacData,
  FacDealType, ReinsuredAddress
} from '../models/fac.model'
import { formatFacPolicyLimit } from './formatFacPolicyLimit'
import { getCertificateNameAndAddress } from '../utils/certificates/getCertificateNameAndAddress'
import { formatDateTime, isDate } from '@utils'
import { formatValue } from '@deals/formatters/formatDecimals'

export function createFacFieldValueFormatter(
  formType: FacDealType,
  fieldMap: Map<string, string>,
  certificateFields: CertificateFields[] = [],
  facData?: FacData,
  reinsuredAddress?: ReinsuredAddress | null
) {
  function getValue(id: string): unknown {
    const v = certificateFields.find(f => f.name === id)?.value?.toString() ?? ''
    if (v) {
      return v
    }
    switch (id) {
      case 'nameOfInsured': {
        return fieldMap.get('name') ?? ''
      }
      case 'company': {
        return fieldMap.get('reinsured') ?? ''
      }
      case 'addressOfInsured': {
        const street = fieldMap.get('streetAddress')
        const city = fieldMap.get('cityName')
        const state = fieldMap.get('stateName')
        const zip = fieldMap.get('zipCode')
        return `${street} ${city} ${state} ${zip}`.trim()
      }
      case 'policyLimit': {
        return formatFacPolicyLimit(
          formType,
          fieldMap.get('coverageReinsured'),
          fieldMap.get('policyLimit'),
          fieldMap.get('generalAgg'),
          fieldMap.get('otherAgg'),
          fieldMap.get('coverageExpression'),
          fieldMap.get('isQuotaShare'),
          fieldMap.get('partOfCoverageLimit'),
          fieldMap.get('partOfGeneralAggregate'),
          fieldMap.get('partOfProductOperationAggregate')
        )
      }
      default: {
        const fieldValue = fieldMap.get(id) ?? ''
        return fieldValue?.toString()?.endsWith('%') ? formatValue(fieldValue, 2) : fieldValue?.toString()
      }
    }
  }
  return (id?: string): string => {
    if (!id) {
      return ''
    }
    if (id === 'nameAndAddress') {
      return getCertificateNameAndAddress(facData, reinsuredAddress)
    }
    if ((id === 'policyNumber') && facData && !!facData[id] && facData[id] !== '') {
      return facData[id] ?? ''
    }
    const value = getValue(id)
    if (value) {
      if (typeof value === 'string') {
        return value
      }
      if (isDate(value)) {
        return formatDateTime(value as Date, 'MM/dd/yyyy')
      }
      if (Array.isArray(value)) {
        return value.join('\n')
      }
    }
    return ''
  }
}
