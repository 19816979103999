import {
  CoverageReinsured,
  FacExportData,
  FacFormID,
  FieldConfig,
  StoredFile,
  StoredFileStatus
} from '../../models'
import { IReportGenerator, ReportResult } from './report-generator.interface'
import {
  FacultativePDF,
} from '../pdf/fac-pdf'
import { buildFacExportQtcUnderwriting } from './fac-export-qtc-underwriting'
import {
  buildExportFields,
  buildReinsuredLayersFields,
  createBasicHeader,
  createLocktonAddressFooter,
} from './utils'
import { createFacFieldValueFormatter } from '../../formatters'
import { FileDownloadService } from './file-download.service'
import { FilenameBuilderFactoryService } from './filename-builder.service'
import { FacExportField } from './models'
import { bytesToBase64 } from '@shared/util'
import { lastValueFrom } from 'rxjs'
import { Html2PdfService } from '@core/services'

interface ReportContext {
  readonly pdf: FacultativePDF
  readonly data: Readonly<FacExportData>
  readonly hiddenFields: string[]
  readonly isEmail: boolean
}

export class ReportGeneratorSubmission extends IReportGenerator {
  private formID: FacFormID = 'submission'

  constructor(
    private fileDownloadService: FileDownloadService,
    private filenameBuilderFactory: FilenameBuilderFactoryService,
    private html2PdfService: Html2PdfService
  ) {
    super()
  }

  async generate(data: FacExportData): Promise<ReportResult> {
    const fieldConfigs: FieldConfig[][] = data.fieldConfigs[0].fields
    const ctx: ReportContext = {
      pdf: new FacultativePDF(),
      data,
      hiddenFields: data.hiddenFields ?? [],
      isEmail: data.isEmail ?? false
    }
    ctx.pdf.salesforceOppId = data?.salesforceOppId

    this.addHeader(ctx.pdf)

    const sliceIndex = data.dealType === 'property' ? 13 : 16
    this.addFieldsFromConfig(ctx, fieldConfigs.slice(0, sliceIndex))
    this.addReinsuredLayers(ctx)
    this.addFieldsFromConfig(ctx, fieldConfigs.slice(sliceIndex))
    this.exportAdditionalInfo(data, ctx)
    this.exportUnderwritingFields(data, ctx)


    if (ctx.data.dealType === 'casualty') {
      ctx.pdf.addTextCentered('SEE NEXT PAGE FOR ANY BROKER COMMENTS', 'italic')
      ctx.pdf.addItems(createLocktonAddressFooter(data), this.formID, data)
      ctx.pdf.addPageNumber()

      const pdfName: string = this.buildReportFileName(ctx.data)
      const pdfArrayBuffers: ArrayBuffer[] = [ctx.pdf.getArrayBuffer()]

      // HTML to PDF conversion
      await this.appendHtmlToPdfPages(ctx, pdfArrayBuffers)
      const pdfBytes: Uint8Array = await ctx.pdf.mergeAndSavePdfs(pdfName, pdfArrayBuffers)

      const storedFileItemList: ReadonlyArray<Readonly<StoredFile>> = this.getAttachedFileInfo(data)
      const fileNames = storedFileItemList.map((f: any) => `${f.id}/${f.name}`)

      if (!ctx.isEmail) {
        this.downloadAttachedFiles(storedFileItemList)
      }

      const pdfBase64: string = bytesToBase64(pdfBytes)
      return { contentType: 'pdf', contents: pdfBase64, reportName: pdfName, files: fileNames }

    } else if (ctx.data.dealType === 'property') {
      ctx.pdf.addItems(createLocktonAddressFooter(data), this.formID, data)

      const pdfName: string = this.buildReportFileName(ctx.data)
      const storedFileItemList: ReadonlyArray<Readonly<StoredFile>> = this.getAttachedFileInfo(data)
      const fileNames = storedFileItemList.map((f: any) => `${f.id}/${f.name}`)
      await ctx.pdf.save(pdfName)
      if (!ctx.isEmail) {
        this.downloadAttachedFiles(storedFileItemList)
      }
      return { contentType: 'pdf', contents: ctx.pdf.getBase64Contents(), reportName: pdfName, files: fileNames }
    }
  }

  private exportAdditionalInfo(data: FacExportData, ctx: ReportContext) {
    if (data.dealType === 'property') {
      this.addAdditionalInfo(ctx.pdf, data)
    }
  }

  private async appendHtmlToPdfPages(ctx: ReportContext, pdfArrayBuffers: ArrayBuffer[]) {
    if (ctx.data.dealType === 'casualty') {
      const innerHtml = ctx.data.fieldMap.get('brokerComments') ?? ''
      if (innerHtml) {
        const commentsArrayBuffer = await lastValueFrom(this.html2PdfService.convertHtml2Pdf('Broker Comments', innerHtml))
        pdfArrayBuffers.push(commentsArrayBuffer)
      }
    } else if (ctx.data.dealType === 'property') {
      const innerHtml = ctx.data.fieldMap.get('additionalInfo') ?? ''
      if (innerHtml) {
        const commentsArrayBuffer = await lastValueFrom(this.html2PdfService.convertHtml2Pdf('Additional Information', innerHtml))
        pdfArrayBuffers.push(commentsArrayBuffer)
      }
    }
  }

  private exportUnderwritingFields(data: FacExportData, ctx: ReportContext) {
    if (data.dealType !== 'casualty') {
      return
    }
    const facVis = data.underwritingVisibility
    const uwFields = buildFacExportQtcUnderwriting(
      1, // ordinal + 1,
      data,
      this.formID,
      facVis
    )
    ctx.pdf.addItems(uwFields, this.formID, data.fieldMap)
  }

  private async downloadAttachedFiles(storedFileItemList: ReadonlyArray<Readonly<StoredFile>>) {
    if (!storedFileItemList) {
      return
    }
    await this.fileDownloadService.downloadToZip(storedFileItemList, 'FacDocuments.zip')
  }

  private getAttachedFileInfo(data: FacExportData): ReadonlyArray<Readonly<StoredFile>> {
    const existingFilesField = data.fieldMap.get('storedFiles')
    const storedFiles: StoredFileStatus[] = existingFilesField ? JSON.parse(existingFilesField) as StoredFileStatus[] : []
    return storedFiles.map(f => f.item)
  }

  private buildReportFileName(data: FacExportData) {
    const coverageReinsured = data.fieldMap.get('coverageReinsured') as CoverageReinsured
    const marketName = data.reinsuranceData?.length ? data.reinsuranceData[0].reinsurers : ''
    const fileNameBuilder = this.filenameBuilderFactory.create()
    fileNameBuilder.setDealName(data.fieldMap.get('name') ?? '')
      .setDealType(data.dealType)
      .setFormType(data.formCategory)
      .setCedentName(data.fieldMap.get('reinsured') ?? '')
      .setCoverageType(coverageReinsured)
      .setMarketName(marketName)
    return fileNameBuilder.build()
  }

  private addAdditionalInfo(pdf: FacultativePDF, data: FacExportData) {
    const additionalInfoText = data.fieldMap.get('additionalInfo') ?? ''
    const additionalInfoField: FacExportField = {
      id: 'additionalInfo',
      type: 'field',
      label: 'Additional Information',
      value: additionalInfoText,
      index: -1,
    }
    pdf.addItems([additionalInfoField], this.formID)
  }

  private addHeader(pdf: FacultativePDF) {
    const title = 'Facultative Reinsurance Submission'
    pdf.addPage(createBasicHeader(title))
  }

  private addFieldsFromConfig(ctx: ReportContext, configFields: FieldConfig[][]) {
    const exportFields = buildExportFields(configFields, ctx.hiddenFields, this.createFieldValueFormatter(ctx.data))
    ctx.pdf.addItems(exportFields, this.formID, ctx.data.fieldMap)
  }

  private addReinsuredLayers(ctx: ReportContext) {
    const specialFields = buildReinsuredLayersFields(
      ctx.data,
      {
        insertIndex: 13,
        reinsuredLimitsLabel: 'Reinsurance Limit(s) Requested Hereon',
      },
      this.formID
    )
    const exportFields = specialFields.map((f: any) => {
      const exportField: FacExportField = {
        hidden: false,
        label: f.label,
        type: 'field',
        value: f.value
      }
      return exportField
    })
    ctx.pdf.addItems(exportFields, this.formID, ctx.data.fieldMap)
  }

  private createFieldValueFormatter(data: FacExportData): (id?: string) => string {
    return createFacFieldValueFormatter(
      data.dealType,
      data.fieldMap,
      data.certificateFields,
      data.facData,
      data.reinsuredAddress,
    )
  }
}
